/*
  Resume Website project
  Author: Dan J. Hamilton
  Date:   15 February 2016

  Filename:         index.html
  Supporting files: modernizr-1.5.js, styles_main.css
*/
import React from "react";
import { Link } from "gatsby";

import SEO from "../../components/seo";
import "../../styles/resume_styles.css";

export default () => (
  <>
    <SEO title="Resume of Dan J. Hamilton" />

    <div className="all-content">
      <div className="nav">
        <ul>
          <li><Link to="/index-project/" title="Project Index">Project Index</Link></li>
          <li><Link to="/resume/" title="Welcome Page">Welcome Page</Link></li>
          <li><Link to="/resume/employmenthistory/" title="Resume">Resume</Link></li>
          <li><Link to="/resume/bio/" title="Biography">Biography</Link></li>
          <li><Link to="/resume/music/" title="Favorite Music">Favorite Music</Link></li>
          <li><Link to="/resume/food/" title="Favorite Food">Favorite Food</Link></li>
          <li><Link to="/resume/about/" title="About this Website">About this Website</Link></li>
        </ul>
      </div>

      <section className="resume-main-content">
        <div className="section-header">
          <h1>About this Website</h1>
        </div>

        <p id="firstp">
			    This Website is a homework project for my Web Site Development course at Valencia College.<br /><br />
			    I guess it is time to declare victory and say this site is finished.<br /><br />
        </p>

        <p>
			    I found the Styx Logo at www.toddsucherman.com
        </p>
      </section>
 
      <div className="footer">
        <div className="address">
          Winter Garden, FL &nbsp;34787
        </div>
      </div>

    </div>
  </>
)